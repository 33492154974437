import React from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function BaseLayout({ children }) {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      />
      <div className="bg-dark fixed inset-0"></div>

      {children}
    </>
  )
}
